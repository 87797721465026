import Moment from 'moment'
import Card from 'components/card'
import React, { useState } from 'react'
import appConstants from 'common/config/appConstants'
import { FaRegCopy } from 'react-icons/fa'
import { IoMdEye } from 'react-icons/io'
import { BiHide } from 'react-icons/bi'
import TooltipHorizon from '../../../components/tooltip'

/**
 * Renders Webservice Event Logs component.
 *
 * @param {Object} toggleState - The current state of toggling
 * @param {Function} setToggleState - A function to set the toggle state
 * @param {Array} workflowRuns - Array of workflow runs data
 * @return {JSX.Element} The Webservice Event Logs component
 */
const WebserviceEventLogs = ({ toggleState, billingData }) => {
  const { Billing, SUBSCRIPTION } = appConstants
  const [isHovered, setIsHovered] = useState(false)
  const [showCopiedText, setShowCopiedText] = useState(false)
  const [showFullKey, setShowFullKey] = useState(false)

  /**
   * Returns a masked version of the given key. If the key is null or undefined,
   * an empty string is returned. If the key length is less than or equal to 12,
   * the original key is returned. Otherwise, the first six characters of the key
   * are concatenated with a masked middle section of '*' repeated to fill the
   * remaining characters, and the last six characters of the key.
   *
   * @param {string} key - The key to be masked.
   * @return {string} The masked key.
   */
  const renderMaskedKey = (key) => {
    if (!key) return ''

    const keyLength = key.length

    if (keyLength <= 12) {
      return key
    }

    const firstSix = key.slice(0, 6)
    const lastSix = key.slice(-6)
    const maskedMiddle = '*'.repeat(keyLength - 12)

    return `${firstSix}${maskedMiddle}${lastSix}`
  }

  /**
   * Copies the license key to the clipboard and displays a message indicating that the key has been copied.
   *
   * @return {void}
   */
  const copyLicenseKey = () => {
    navigator.clipboard.writeText(billingData?.key)
    setShowCopiedText(true)
    setTimeout(() => {
      setShowCopiedText(false)
    }, 1000)
  }

  return (
    <>
      {/* events web services */}
      {toggleState === Billing.BILLING_TABS_TEXT && (
        <Card extra="w-full !pb-2">
          <div className="ml-4 mt-4 grid grid-cols-2 gap-4">
            <div>
              <div className="font-bold">{SUBSCRIPTION.PLANS}</div>
              <div className="capitalize">
                {billingData?.planId?.name || '-'} /{' '}
                {billingData?.planId?.duration || '-'}
              </div>
            </div>
            <div>
              <div className="font-bold">{SUBSCRIPTION.START_DATE}</div>
              <div>
                {billingData?.activatedOn
                  ? Moment(billingData?.activatedOn).format('MMMM Do YYYY')
                  : '-'}
              </div>
            </div>
            <div>
              <div className="font-bold">{SUBSCRIPTION.END_DATE}</div>
              <div>
                {billingData?.deactivatesOn
                  ? Moment(billingData?.deactivatesOn).format('MMMM Do YYYY')
                  : '-'}
              </div>
            </div>
            <div>
              <div className="font-bold">{SUBSCRIPTION.EXPIRING}</div>
              <div>
                {billingData?.expiringBy || '-'}{' '}
                {SUBSCRIPTION.DAYS_REMAINING || '-'}
              </div>
            </div>
            <div className="col-span-2">
              <div className="flex items-center pb-1">
                <div className="flex items-center">
                  <div className="mr-2 font-bold">
                    {SUBSCRIPTION.LICENSE_KEY}
                  </div>
                </div>
                <div className="flex items-center">
                  {showCopiedText ? (
                    <span className="text-gray mr-[10px]">
                      {SUBSCRIPTION.COPY}
                    </span>
                  ) : (
                    <TooltipHorizon
                      extra="border border-gray-200 dark:border-gray-700"
                      trigger={
                        <p
                          className="mr-[10px] flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                          onClick={copyLicenseKey}
                        >
                          <span>
                            <FaRegCopy className="text-xl " />
                          </span>
                        </p>
                      }
                      content="Copy"
                      placement="top"
                    />
                  )}
                  {showFullKey && (
                    <>
                      <TooltipHorizon
                        extra="border border-gray-200 dark:border-gray-700"
                        trigger={
                          <p
                            className="mr-[20px] flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                            onClick={() => setShowFullKey(false)}
                          >
                            <span>
                              <BiHide className="text-xl" />
                            </span>
                          </p>
                        }
                        content="Hide"
                        placement="top"
                      />
                    </>
                  )}
                  {!showFullKey && (
                    <TooltipHorizon
                      extra="border border-gray-200 dark:border-gray-700"
                      trigger={
                        <p
                          className="mr-[20px] flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                          onClick={() => setShowFullKey(true)}
                        >
                          <span>
                            <IoMdEye className="text-xl " />
                          </span>
                        </p>
                      }
                      content="View"
                      placement="top"
                    />
                  )}
                </div>
              </div>
              {showFullKey && (
                <div
                  className="w-[98%] break-words break-all"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {isHovered
                    ? billingData?.key
                    : renderMaskedKey(billingData?.key || '-')}{' '}
                </div>
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  )
}

export default WebserviceEventLogs
