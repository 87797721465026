import loginImg from 'assets/img/others/allvest-logo.png'
import { useNavigate } from 'react-router-dom'
import { useToaster } from 'common/Toaster'
import appConstants from 'common/config/appConstants'
import { useSpinner } from 'common/SpinnerLoader'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { useEffect, useState, useCallback } from 'react'
import { getOrganization, handleBillingApiCall } from 'common/commonFunction'

const { TOAST, SIGNIN } = appConstants
const apiService = ApiCaller()

/**
 * Function for signing in with Keycloak.
 *
 * @return {JSX.Element} The sign in component.
 */
function SignInDefault() {
  const [myCode, setMyCode] = useState('')
  const [ignore, setIgnore] = useState(false)
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const confixa_app_url = process.env.REACT_APP_CONFIXA

  let navigate = useNavigate()

  /**
   * Asynchronously checks the license and handles the sign-in or navigation to the subscription expired page.
   *
   * @return {Promise<void>} Promise that resolves when the function completes.
   */
  const checkLicense = async () => {
    try {
      showSpinner()
      handleSignIn()
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const handleToken = useCallback(async () => {
    try {
      showSpinner()
      let payload = {}
      payload = {
        code: myCode,
      }
      const apiUrl = apiConfig.CODE_SIGN_IN
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (
        response?.data?.code === 200 &&
        response?.data?.data?.token?.token !== ''
      ) {
        setIgnore(true)
        localStorage.setItem('accessToken', response?.data?.data?.access_token)
        localStorage.setItem('darkMode', JSON.stringify(false))
        navigate('/')
        getOrganizationData()
        addToast({
          title: TOAST.MESSAGES.SUCCESS.signInSuccess,
          type: 'success',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCode])

  const getOrganizationData = async () => {
    try {
      showSpinner()
      const response = await getOrganization()
      if (response?.data?.code === 200) {
        const responseData = response?.data?.data
        const org_Data = {
          org_name: responseData?.name,
          repo_name: responseData?.repoName,
          branch_name: responseData?.env?.map((env) => env.name),
          id: responseData?._id,
          github_token: responseData?.githubToken,
          cloud_type: responseData?.cloudType,
        }
        localStorage.setItem('organizationData', JSON.stringify(org_Data))
      } else {
        navigate(`/`)
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * A function that handles the sign in process.
   *
   * @param {} - no parameters
   * @return {} - no return value
   */
  const handleSignIn = () => {
    const base_url_sign_in = process.env.REACT_APP_BASE_URL_SIGN_IN
    const response_type = process.env.REACT_APP_RESPONSE_TYPE
    const client_id = process.env.REACT_APP_CLIENT_ID
    const redirect_uri = process.env.REACT_APP_REDIRECT_URI
    const scope = process.env.REACT_APP_SCOPE

    const anchor = document.createElement('a')
    anchor.href = `${base_url_sign_in}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}`
    anchor.click()
  }

  useEffect(() => {
    const keyCloakUrl = window.location.href
    const myUrl = new URL(keyCloakUrl)
    const code = myUrl.searchParams.get('code')
    setMyCode(code)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (myCode && !ignore) {
          const checkLicenseData = await handleBillingApiCall()
          if (checkLicenseData && checkLicenseData === 'Valid') {
            await handleToken() // Assuming handleToken is an async function
          } else if (checkLicenseData && checkLicenseData !== 'Valid') {
            navigate('/subscription-expired')
          }
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }
    if (myCode !== null) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCode, ignore])

  return (
    <div className="h-screen bg-white md:flex">
      <div className="hidden h-full w-full items-center justify-center bg-white py-10 md:flex md:w-1/2">
        <div className="bg-white pb-40">
          <div className="mb-18 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            <div className="mt-[10vh]  w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
              <h3 className="mb-2.5 text-4xl font-bold text-navy-700 ">
                {SIGNIN.WELCOMETEXT}
              </h3>
              <button
                onClick={() => checkLicense()}
                className="w-full rounded-xl bg-brand-500 p-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 "
              >
                {SIGNIN.SIGNINBUTTONTEXT}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex h-full items-center justify-around overflow-hidden rounded-bl-[120px] bg-gradient-to-b from-purple-lightViolet via-purple-hanPurple to-purple-darkHanPurple p-5 md:w-1/2">
        <div className="">
          <img
            src={loginImg}
            alt="AllVest Logo"
            className="mb-4 h-20 w-full object-contain"
          />
          <div className="mt-[10vh] flex w-full max-w-full flex-col items-center md:hidden md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <p className="mb-3 w-full text-4xl font-bold text-white dark:text-navy-700">
              {SIGNIN.WELCOMETEXT}
            </p>
            <button
              onClick={() => checkLicense()}
              className="w-full rounded-xl bg-white p-3 text-base font-medium text-brand-500 transition duration-200  active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200  "
            >
              {SIGNIN.SIGNINBUTTONTEXT}
            </button>
          </div>
          <div className=" text-1xl  mt-[120px] w-full items-center  rounded-xl border border-gray-200 px-9 py-[30px] dark:border-white/10">
            <h6 className="mb-2 pb-1 text-center font-dm text-xl text-white dark:text-white">
              {SIGNIN.LEARNMORE}
            </h6>
            <div className="text-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${confixa_app_url}`}
                className="text-xl font-bold  text-white dark:text-white"
              >
                {SIGNIN.DOMAIN}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignInDefault
