// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* custom-tailwind.css */
.custom-tailwind_resetTailwind__Opg-u * {
  all: revert;
}
`, "",{"version":3,"sources":["webpack://./src/views/chatBot/components/custom-tailwind.module.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,WAAW;AACb","sourcesContent":["/* custom-tailwind.css */\n.resetTailwind * {\n  all: revert;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetTailwind": `custom-tailwind_resetTailwind__Opg-u`
};
export default ___CSS_LOADER_EXPORT___;
