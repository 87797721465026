import Card from 'components/card'
import CompanyList from 'views/company'

function AfterLoginPage() {
  return (
    <Card extra={'w-full h-full bg-white mt-3 px-4'}>
      <CompanyList />
    </Card>
  )
}

export default AfterLoginPage
