const keyCloakUrl = process.env.REACT_APP_API_BASE_URL
const baseUrl = process.env.REACT_APP_NEW_API_BASE_URL
const unlistedUrl = process.env.REACT_APP_UNLISTED_API_BASE_URL
const licenseBaseUrl = process.env.REACT_APP_LICENSE_API_BASE_URL
const authAppBaseUrl = process.env.REACT_APP_AUTH_APP_BASE_URL
const supportTicketBaseUrl = process.env.REACT_APP_SUPPORT_TICKET_BASE_URL

const config = {
  // Application
  GET_ALL_APPLICATION: baseUrl + 'api/Application/getAll',
  DELETE_APPLICATION: baseUrl + 'api/Application/delete',

  // Project
  CREATE_PROJECT: baseUrl + 'api/project/create',
  GET_ALL_PROJECTS: baseUrl + 'api/project/getAllProjects',
  GET_PROJECT_DETAILS: baseUrl + 'api/project/get/:id',
  EDIT_PROJECT: baseUrl + 'api/project/edit/:id',

  //Create A New Web Services
  GET_PUBLIC_GIT_REPOSITORY: baseUrl + 'api/Repository/getAll',
  POST_PUBLIC_REPOSITORY: baseUrl + 'api/Repository/getPublicRepo',

  //GET ALL BRANCHES
  GET_ALL_BRANCHES:
    baseUrl + 'api/Branch/getAll/:organization_id/:owner_name/:repo_name',

  //DEPLOYING WEB SERVICE
  CREATE_NEW_SERVICE: baseUrl + 'api/Application/create',

  //WebServices
  WORK_FLOWS_GET_EVENTS:
    baseUrl + 'api/Workflow/getEvents?applicationId=:application_id&page=:page',
  GET_WORK_FLOWS_RUNS:
    baseUrl + 'api/Workflow/getRun/:application_id/:workflow_id/:page',
  GET_WORK_FLOWS_JOBS:
    baseUrl + 'api/Workflow/getJob/:application_id/:workflow_runs_id',

  //SETTINGS/GIT
  VERIFY_GIT_TOKEN: baseUrl + 'api/Token/verify/:organization_id',
  //Get Git TOken
  GET_GIT_TOKEN: baseUrl + 'api/Token/get/:organization_id',

  //SETTINGS/ARGOCD
  ARGO_CD: keyCloakUrl + 'api/argocd/verifyargocd',
  GET_ARGO_CD_TOKEN: keyCloakUrl + 'api/argocd/getArgocdToken',

  //SETTINGS/ArtiFactRepository
  ARTIFACT_REPOSITORY: baseUrl + 'api/Artifact/save/:organization_id',
  GET_ARTIFACT_DATA: baseUrl + 'api/Artifact/get/:organization_id',
  UPDATE_ARTIFACT_FORM: baseUrl + 'api/Artifact/update/:organization_id',
  DOWNLOAD_ARTIFACT_DATA: baseUrl + 'api/Artifact/download/:organization_id',
  //Sign In
  CODE_SIGN_IN: keyCloakUrl + 'api/Keycloak/getKeycloakToken',
  //get User details
  GET_USER_INFO: keyCloakUrl + 'api/Keycloak/userInfo',

  //Logout api
  LOGOUT_USER: keyCloakUrl + 'api/Keycloak/logout',

  //Elastic Cloud AI Assistant
  // BotData
  CHAT_DATA: baseUrl + 'api/Chat/ask',

  //Previously Data
  // BotData
  PREVIOUSLY_DATA: baseUrl + 'api/Chat/getTopic',
  PREVIOUSLY_INSIDE_DATA: baseUrl + 'api/Chat/getMessage/:topic_id',
  DELETE_DATA: baseUrl + 'api/Chat/delete/:topic_id',

  //Check If Repo name is already taken
  CHECK_REPO: baseUrl + 'api/Organization/checkRepoName',

  //Create Organization
  SET_ORGANIZATION: baseUrl + 'api/Organization/create',

  ORGANIZATION_ENV: baseUrl + 'api/Organization/getEnv',

  //Get Organization Details
  GET_ORGANIZATION_DETAILS: baseUrl + 'api/Organization/get',

  GET_PROJECTS_DATA: baseUrl + 'api/Project/checkName',

  GET_ORGANIZATION_DATA: baseUrl + 'api/Organization/checkName',
  //Get Application checkName
  GET_APPLICATION_CHECKNAME: baseUrl + 'api/Application/checkName',

  //config_table_api
  GET_CONFIG_TABLE_DATA:
    baseUrl + 'api/Application/getConfigMap/:application_id/:branch_id',
  UPDATE_CONFIG_TABLE_DATA:
    baseUrl + 'api/Application/updateConfigMap/:application_id/:branch_id',
  DELETE_CONFIG_TABLE_DATA:
    baseUrl +
    'api/Application/deleteKeysFromConfigMap/:application_id/:branch_id',

  GET_SECRET_TABLE_DATA:
    baseUrl + 'api/Application/getSecret/:application_id/:branch_id',
  UPDATE_SECRET_TABLE_DATA:
    baseUrl + 'api/Application/updateSecret/:application_id/:branch_id',

  UPDATE_VALUES_TABLE_DATA:
    baseUrl + 'api/Project/updateValues/:project_id/:branch_id',
  GET_VALUES_TABLE_DATA:
    baseUrl + 'api/Project/getValues/:project_id/:branch_id',

  //Issue list
  ISSUES_LISTNING: baseUrl + 'api/Llm/getAllIssues',

  //Add Issue
  ADD_ISSUE: baseUrl + 'api/Llm/getPR',

  //Update Issue
  UPDATE_ISSUE: baseUrl + 'api/Llm/updateIssue',

  //create branch
  CREATE_BRANCH: baseUrl + 'api/Branch/:type/:id',

  //get all-label
  GET_ALL_LABEL: baseUrl + 'api/Llm/getAllLabels',

  //create label
  CREATE_LABEL: baseUrl + 'api/Llm/:type',

  //delete label
  DELETE_LABEL: baseUrl + 'api/Llm/deleteLabel',
  // Re-run jobs
  RERUN_JOBS: baseUrl + 'api/Workflow/rerun/:applicationId/:jobId',

  // Cancel jobs
  CANCEL_JOBS: baseUrl + 'api/Workflow/cancel/:applicationId/:runId',

  // DASHBOARD
  GET_K8S_METRICS: baseUrl + 'api/Monitoring/get',
  // GET All Authors
  GET_ALL_AUTHORS: baseUrl + 'api/Llm/getAllAuthors',

  // check Branch
  CHECK_BRANCH_NAME: baseUrl + 'api/Branch/checkBranchName',

  GET_ALL_BILLING:
    licenseBaseUrl + 'api/subscription/getOneDetail?domainName=:domain',
  // SET_ALL_BILLING: licenseBaseUrl + 'api/subscription/checkSubscription',
  GET_DOMAINS_LIST: baseUrl + 'api/Application/getDomains/:applicationId',

  GET_ELASTIC_DATA: baseUrl + 'api/Elastic/get?start=:startTime&end=:endTime',

  GET_ALL_APPLICATION_VERSION_DATA:
    baseUrl + 'api/Application/getAllVersions/:serviceType',

  GET_KUBE_COST_DATA: baseUrl + 'api/KubeCost/getKubeCost',

  //checkDomainName
  CHECK_DOMAINNAME: baseUrl + 'api/Application/checkDomainName',

  UPDATE_PORT: baseUrl + 'api/Application/updatePort',

  GET_APPLICATION_INFO: baseUrl + 'api/Application/get/:applicationId',

  GET_UNSELECTED_ENV: baseUrl + 'api/Project/getUnselectedEnv/:id',

  GET_SELECTED_ENV: baseUrl + 'api/Project/getSelectedEnv/:projectId',

  UPDATE_DOMAIN_ARTIFACT: baseUrl + 'api/Application/editDomainAndArtifact',

  GITHUB_AUTH: authAppBaseUrl + 'api/Auth/githubAuth',

  SUPPORT_TICKET_LIST: supportTicketBaseUrl + 'api/Ticket/list',

  SUPPORT_TICKET_DETAILS: supportTicketBaseUrl + 'api/Ticket/get/:ticketId',

  SUPPORT_TICKET_UPDATE: supportTicketBaseUrl + 'api/Ticket/update/:ticketId',

  SUPPORT_TICKET_FILTER: supportTicketBaseUrl + 'api/Ticket/filter',

  SUPPORT_TICKET_CONVERSATION:
    supportTicketBaseUrl + 'api/Conversation/get/:ticketId',

  SUPPORT_CREATE_CONVERSATION: supportTicketBaseUrl + 'api/Conversation/create',

  SUPPORT_CREATE_TICKET: supportTicketBaseUrl + 'api/Ticket/create',

  //Wealth Management API
  // User Authentication API
  LOGOUT_USER: keyCloakUrl + 'api/KeyCloak/logout',
  USER_SIGNIN: baseUrl + 'api/KeyCloak/userLogin',
  USER_SIGNUP: baseUrl + 'api/KeyCloak/createUser',

  GET_ALL_UNLISTED_COMPANIES:
    unlistedUrl + 'api/unlistedCompany/getAllUnlistedCompanyDetails',

  GET_UNLISTED_COMPANY_BY_ID:
    unlistedUrl + 'api/unlistedCompany/getByIdUnlistedCompanyDetails',

  GET_ALL_SECTOR: unlistedUrl + 'api/unlistedCompany/getAllCompanySectors',

  GET_ALL_UNLISTED_COMPANIES_WOUT_PAGING:
    unlistedUrl + 'api/unlistedCompany/getAllUnlistedCompanyNames',
  READ_FILE:
    'https://read-file-api-dev.allvestfinance.in/api/readFile/readFile',
}

export default config
