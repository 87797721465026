import React, { useState, useEffect } from 'react'
import {
  MdSupervisedUserCircle,
  MdOutlineAccountBalanceWallet,
  MdSettings,
  MdOutlineFolderOpen,
  MdDashboardCustomize,
  MdCloudQueue,
} from 'react-icons/md'
import { TbBrandElastic } from 'react-icons/tb'
import DashboardsDefault from 'views/dashboards/default'
// import SecurityAndPoliciesIFrame from 'views/securityAndPolicies/components/SecurityAndPoliciesIFrame'
import Billing from './views/billing'
import InfoUrlComponent from './common/UrlConfigurationCheck'
// import GitInvite from 'views/settings/git'
// import ArgoCDInvite from 'views/settings/argoCD'
import ChatContainer from './views/chatBot/index'
// import ElasticIFrame from 'views/observability'
// import { GrShieldSecurity } from 'react-icons/gr'
import { SiAzureartifacts } from 'react-icons/si'
// import { MdOutlineDns } from 'react-icons/md'
// import { SiArgo } from 'react-icons/si'
import { useParams } from 'react-router-dom'
import { SiUptimekuma } from 'react-icons/si'
import AfterLoginPage from 'views/afterloginpages'
import { AiOutlineDashboard } from 'react-icons/ai'
import { IoBarChartOutline, IoGitBranchOutline } from 'react-icons/io5'
import appConstants from 'common/config/appConstants'
import UnlistedCompanyList from 'views/unlistedcompany'
import CompanyDetail from 'views/companydetails'




const RoutesComponent = () => {
  const { id, applicationType, application_id } = useParams()
  const { URL_CONFIGURATION } = appConstants
  const [projectName, setProjectName] = useState()
  const [serviceName, setServiceName] = useState()
  const [routes, setRoutes] = useState([])
  const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL

  useEffect(() => {
    const storedProjectName = localStorage.getItem('projectName')
    const storedServiceName = localStorage.getItem('serviceName')
    if (storedServiceName) {
      setServiceName(storedServiceName)
    }
    if (storedProjectName) {
      setProjectName(storedProjectName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('projectName'), localStorage.getItem('serviceName')])

  useEffect(() => {
    setRoutes([
      {
        name: 'All Companies',
        path: '/',
        layout: '/afterLogin',
        icon: <AiOutlineDashboard className="text-inherit h-6 w-6" />,
        collapse: false,
        // component: <AfterLoginPage />,
        component: <UnlistedCompanyList />,
        isComing: false,
        isVisible: false,
      },
      {
        name: 'Unlisted Companies',
        path: '/unlisted-companies',
        layout: '/afterLogin',
        icon: <AiOutlineDashboard className="text-inherit h-6 w-6" />,
        collapse: false,
        component: <UnlistedCompanyList />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Company Details',
        path: '/unlisted-companies/company-details/:id',
        layout: '/afterLogin',
        icon: <AiOutlineDashboard className="text-inherit h-6 w-6" />,
        collapse: false,
        component: <CompanyDetail />,
        isComing: false,
        isVisible: false,
      },

      // {
      //   name: 'Company',
      //   layout: '/afterLogin',
      //   path: '/view/company',
      //   component: <Company />,
      //   isComing: false,
      //   isVisible: false,
      //   breadcrum: [{ label: 'subscription', link: '/subscription' }],
      // },
      // {
      //   name: 'Subscription Details',
      //   layout: '/afterLogin',
      //   path: '/subscription',
      //   component: <Billing />,
      //   isComing: false,
      //   isVisible: false,
      //   breadcrum: [{ label: 'subscription', link: '/subscription' }],
      // },
      // {
      //   name: 'Observability',
      //   path: observabilityUrl,
      //   // path: '/observability',
      //   layout: '/afterLogin',
      //   icon: <TbBrandElastic className="text-inherit h-6 w-6" />,
      //   // component: <ElasticIFrame />,
      //   collapse: false,
      //   isVisible: true,
      //   isComing: false,
      //   openInNewTab: true,
      // },
      // {
      //   name: 'Observability',
      //   path: '/observability',
      //   icon: <TbBrandElastic className="text-inherit h-6 w-6" />,
      //   collapse: true,
      //   items: [
      //     // {
      //     //   name: 'ELK',
      //     //   layout: '/afterLogin',
      //     //   path: observabilityUrl,
      //     //   // path: '/observability/elk',
      //     //   component: <GitInvite />,
      //     //   icon: <SiElasticsearch className="text-inherit h-6 w-6" />,
      //     //   collapse: false,
      //     //   isVisible: true,
      //     //   isComing: false,
      //     //   openInNewTab: true,
      //     //   parentTitle: 'Observability',
      //     //   // breadcrum: [{ label: 'observability', link: '/observability' }],
      //     // },
      //     {
      //       name: 'Logs',
      //       layout: '/afterLogin',
      //       path: 'observability-logs',
      //       component: (
      //         <InfoUrlComponent
      //           url={URL_CONFIGURATION.LOG_MONITORING_URL}
      //           title={URL_CONFIGURATION.LOG_MONITORING_TITLE}
      //           text={URL_CONFIGURATION.LOG_MONITORING_INFO}
      //           img={URL_CONFIGURATION.LOG_MONITORING_IMG}
      //         />
      //       ),
      //       icon: <MdOutlineFolderOpen className="text-inherit h-6 w-6" />,
      //       collapse: false,
      //       isVisible: true,
      //       isComing: false,
      //       parentTitle: 'Observability',
      //       breadcrum: [{ label: 'Logs', link: '/logs' }],
      //     },
      //     {
      //       name: 'Metrics',
      //       layout: '/afterLogin',
      //       path: 'observability-metrics',
      //       component: (
      //         <InfoUrlComponent
      //           url={URL_CONFIGURATION.METRICS_MONITORING_URL}
      //           title={URL_CONFIGURATION.METRICS_MONITORING_TITLE}
      //           text={URL_CONFIGURATION.METRICS_MONITORING_INFO}
      //           img={URL_CONFIGURATION.METRICS_MONITORING_IMG}
      //         />
      //       ),
      //       icon: <IoBarChartOutline className="text-inherit h-6 w-6" />,
      //       collapse: false,
      //       isVisible: true,
      //       isComing: false,
      //       parentTitle: 'Observability',
      //       breadcrum: [{ label: 'metrics', link: '/metrics' }],
      //     },
      //     {
      //       name: 'Traces',
      //       layout: '/afterLogin',
      //       path: 'observability-traces',
      //       component: (
      //         <InfoUrlComponent
      //           url={URL_CONFIGURATION.TRACING_FOR_SYSTEMS_URL}
      //           title={URL_CONFIGURATION.TRACING_FOR_SYSTEMS_TITLE}
      //           text={URL_CONFIGURATION.TRACING_FOR_SYSTEMS_INFO}
      //           img={URL_CONFIGURATION.TRACING_FOR_SYSTEMS_IMG}
      //         />
      //       ),
      //       icon: <IoGitBranchOutline className="text-inherit h-6 w-6" />,
      //       collapse: false,
      //       isVisible: true,
      //       isComing: false,
      //       parentTitle: 'Observability',
      //       breadcrum: [{ label: 'tracing', link: '/tracing' }],
      //     },
      //   ],
      // },
      // {
      //   name: 'System Health',
      //   path: 'system-health',
      //   component: (
      //     <InfoUrlComponent
      //       url={URL_CONFIGURATION.SYSTEM_HEALTH_URL}
      //       title={URL_CONFIGURATION.SYSTEM_HEALTH_TITLE}
      //       text={URL_CONFIGURATION.SYSTEM_HEALTH_INFO}
      //       img={URL_CONFIGURATION.SYSTEM_HEALTH_IMG}
      //     />
      //   ),
      //   layout: '/afterLogin',
      //   icon: <SiUptimekuma className="text-inherit h-6 w-6" />,
      //   isComing: false,
      //   isVisible: true,
      // },
      // {
      //   name: 'Continuous Delivery',
      //   path: 'continuous-delivery',
      //   component: (
      //     <InfoUrlComponent
      //       url={URL_CONFIGURATION.CONTINUOUS_DELIVERY_URL}
      //       title={URL_CONFIGURATION.CONTINUOUS_DELIVERY_TITLE}
      //       text={URL_CONFIGURATION.CONTINUOUS_DELIVERY_INFO}
      //       img={URL_CONFIGURATION.CONTINUOUS_DELIVERY_IMG}
      //     />
      //   ),
      //   layout: '/afterLogin',
      //   icon: <MdCloudQueue className="text-inherit h-6 w-6" />,
      //   isVisible: true,
      //   isComing: false,
      // },
      // {
      //   name: 'IAM',
      //   layout: '/afterLogin',
      //   path: keycloakUrl,
      //   icon: <MdSupervisedUserCircle className="text-inherit h-6 w-6" />,
      //   isComing: false,
      //   isVisible: true,
      //   openInNewTab: true,
      // },
      // {
      //   name: 'FinOps',
      //   path: '/finOps',
      //   component: (
      //     <InfoUrlComponent
      //       url={URL_CONFIGURATION.FINOPS_URL}
      //       title={URL_CONFIGURATION.FINOPS_TITLE}
      //       text={URL_CONFIGURATION.FINOPS_INFO}
      //       img={URL_CONFIGURATION.FINOPS_IMG}
      //     />
      //   ),
      //   layout: '/afterLogin',
      //   icon: (
      //     <MdOutlineAccountBalanceWallet className="text-inherit h-6 w-6" />
      //   ),
      //   isVisible: true,
      //   isComing: false,
      // },
      // {
      //   name: 'Cluster Dashboard',
      //   path: '/clusterDashboard',
      //   component: (
      //     <InfoUrlComponent
      //       url={URL_CONFIGURATION.CLUSTER_URL}
      //       title={URL_CONFIGURATION.CLUSTER_TITLE}
      //       text={URL_CONFIGURATION.CLUSTER_INFO}
      //       img={URL_CONFIGURATION.CLUSTER_IMG}
      //     />
      //   ),
      //   layout: '/afterLogin',
      //   icon: <MdDashboardCustomize className="text-inherit h-6 w-6" />,
      //   isComing: false,
      //   isVisible: true,
      // },
      // {
      //   name: 'Just Login',
      //   path: '/afterloginpage',
      //   component: <AfterLoginPage />,
      //   layout: '/afterLogin',
      //   icon: <MdDashboardCustomize className="text-inherit h-6 w-6" />,
      //   isComing: false,
      //   isVisible: true,
      // },
      // {
      //   name: 'Security and Policies',
      //   path: '/security-policies',
      //   layout: '/afterLogin',
      //   icon: <GrShieldSecurity className="text-inherit h-6 w-6" />,
      //   component: <SecurityAndPoliciesIFrame />,
      //   isComing: true,
      //   isVisible: true,
      // },
      // {
      //   name: 'Settings',
      //   path: '/settings',
      //   icon: <MdSettings className="text-inherit h-6 w-6" />,
      //   collapse: true,
      //   items: [
      // {
      //   name: 'Argo CD',
      //   layout: '/afterLogin',
      //   path: '/settings/argoCD',
      //   icon: <SiArgo className="text-inherit h-6 w-6" />,
      //   component: <ArgoCDInvite />,
      //   isComing: false,
      //   isVisible: true,
      //   parentTitle: 'Settings',
      //   breadcrum: [
      //     { label: 'Settings', link: '/settings/argoCD' },
      //     { label: 'Argo CD', link: '/settings/argoCD' },
      //   ],
      // },
      {
        name: `${id ? 'Conversation' : 'New Conversation'}`,
        layout: '/afterLogin',
        path: '/chatbot/:id?',
        isComing: false,
        component: <ChatContainer />,
        isVisible: false,
      },
      // {
      //   name: 'DNS',
      //   layout: '/afterLogin',
      //   icon: <MdOutlineDns className="text-inherit h-6 w-6" />,
      //   path: '/settings/dns',
      //   isComing: true,
      //   isVisible: true,
      //   parentTitle: 'Settings',
      // },
      // ],
      // },
    ])
  }, [projectName, id, applicationType, serviceName, application_id])

  return routes
}

export default RoutesComponent
