export const companyColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Sector',
    accessor: 'sector_name',
    disableSortBy: true,
  },
  {
    Header: 'Price',
    accessor: 'rate',
    disableSortBy: true,
  },
]
