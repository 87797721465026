import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import apiConfig from 'common/config/apiConfig'
import Card from 'components/card'
import ApiCaller from 'common/services/apiServices'
import stock from './assets/img/stock.png'
import logo from './assets/img/logo.png'
import { useToaster } from 'common/Toaster'
import { useSpinner } from 'common/SpinnerLoader'
import webConstants from 'common/config/appConstants'

const { TOAST } = webConstants
const apiService = ApiCaller()

const CompanyDetail = () => {
  const [data, setData] = useState()
  const { id } = useParams()
  const [Unit, setUnit] = useState(0)
  const [logoID, setLogoID] = useState()
  const [logoUrl, setLogoUrl] = useState('')
  const NavOptions = [
    'Fundamentals',
    'Key Financials',
    'Shareholding',
    'Strength & Weaknesses',
    'About Company',
  ]
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()
  const [activeOption, setActiveOption] = useState('Fundamentals')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = apiConfig.GET_UNLISTED_COMPANY_BY_ID + `/${id}`
        const response = await apiService.apiCall('get', apiUrl)
        console.log(response)
        if (response?.data?.status_code === 200) {
          setData(response?.data?.data)
          setLogoID(response?.data?.data?.logo?.id)
          setUnit(response?.data?.data?.minimum_units)

        } else {
          console.error(
            'Error: Unexpected response code',
            response?.data?.status_code
          )
        }
      } catch (error) {
        console.error('Error during API call:', error)
      }
    }
    fetchData()
  }, [id])

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        showSpinner()
        const payload = { id: logoID, dest: 'uploads' }

        try {
          const apiUrl = `${apiConfig.READ_FILE}`
          const response = await apiService.apiCall('post', apiUrl, payload)

          if (response?.data?.status_code === 200) {
            setLogoUrl(response?.data?.data?.url || '')
          } else {
            addToast({
              title: `Failed to fetch logo`,
              type: 'error',
            })
          }
        } catch (error) {
          addToast({
            title: `Error fetching logo : ${error.message}`,
            type: 'error',
          })
        }
      } catch (error) {
        addToast({
          title: 'Failed to fetch updated logo.',
          type: 'error',
        })
      } finally {
        hideSpinner()
      }
    }

    fetchLogo()
  }, [logoID])


  return (
    <Card extra="w-full h-full bg-white mt-3 px-4">
      {/* Up Part */}
      <div className="flex flex-col gap-8 lg:flex-row lg:px-8 lg:py-10">
        {/* Left Part */}
        <div className="rounded-lg bg-white p-5 shadow-md lg:w-[70%]">
          {/* Header */}
          <div className="flex items-center gap-6">
            <div className="h-24 w-24 overflow-hidden rounded-lg border">
              <img
                src={logoUrl}
                alt="Stock"
                className="h-full w-full object-cover"
              />
            </div>
            <h1 className="text-3xl font-bold text-gray-800">{data?.name}</h1>
          </div>

          {/* Current Price */}
          <p className="mt-4 text-5xl font-extrabold text-gray-900">
            ₹ {data?.rate}
          </p>

          {/* Stock Image */}
          <div className="mt-8">
            <img
              src={stock}
              alt="Stock Chart"
              className="w-full rounded-lg shadow"
            />
          </div>
        </div>

        {/* Right Part */}
        <div className="lg:w-[30%]">
          {/* Info Section */}
          <div className="mb-6 rounded-lg bg-gray-100 p-6 shadow-md">
            <h1 className="text-xl font-semibold text-gray-800">
              {data?.name}
            </h1>
            <p className="mt-2 text-2xl font-bold text-gray-900">
              ₹ {data?.rate}
            </p>
          </div>

          {/* Details Section */}
          <div className="rounded-lg bg-white p-6 shadow-md">
            {/* Price Details */}
            <div className="mb-4">
              <div className="flex justify-between text-gray-700">
                <p>Price per Unit</p>
                <p>₹ {data?.rate}</p>
              </div>
              <div className="flex justify-between text-gray-700">
                <p>Minimum Units</p>
                <p>{data?.minimum_units}</p>
              </div>
            </div>

            {/* Quantity Selector */}
            <div className="mb-6">
              <h2 className="mb-4 text-center text-lg font-bold text-gray-800">
                Enter Units
              </h2>
              <div className="flex items-center justify-center gap-6">
                <button
                  type="button"
                  onClick={() => {
                    if (Unit > data?.minimum_units) setUnit(Unit - 1)
                  }}
                  className="rounded-full bg-blue-500 px-4 py-2 font-bold text-white transition hover:bg-blue-600"
                >
                  -
                </button>
                <div className="w-16 rounded-lg border border-gray-300 text-center text-xl font-semibold text-gray-800">
                  {Unit}
                </div>
                <button
                  type="button"
                  onClick={() => setUnit(Unit + 1)}
                  className="rounded-full bg-blue-500 px-4 py-2 font-bold text-white transition hover:bg-blue-600"
                >
                  +
                </button>
              </div>
            </div>

            {/* Investment Details */}
            <div className="mb-6">
              <div className="flex justify-between text-gray-700">
                <p>Investment Amount</p>
                <p>₹ {Unit * data?.rate}</p>
              </div>
              <div className="flex justify-between text-gray-700">
                <p>Total Investment</p>
                <p>₹ {Unit * data?.rate}</p>
              </div>
            </div>

            {/* Invest Now Button */}
            <button className="w-full rounded-lg bg-blue-500 px-5 py-3 text-lg font-bold text-white transition hover:bg-blue-600">
              Invest Now
            </button>
          </div>
        </div>
      </div>

      {/* Down Part */}

      <div className="w-full">
        {/* Navigation Bar */}
        <div className="flex justify-center space-x-4 border-b bg-gray-100 p-4">
          {NavOptions.map((option) => (
            <div
              key={option}
              onClick={() => setActiveOption(option)}
              className={`cursor-pointer px-4 py-2 transition-colors duration-200 ${
                activeOption === option
                  ? 'border-b-2 border-blue-500 font-bold text-blue-500'
                  : 'text-gray-700 hover:text-blue-500'
              }`}
            >
              {option}
            </div>
          ))}
        </div>

        {/* Content Section */}
        <div className="p-6">
          {activeOption === 'Fundamentals' && (
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {[
                { label: 'Current Price', value: `₹ ${data?.current_price}` },
                { label: 'Market Cap', value: `₹ ${data?.market_cap} Cr` },
                { label: 'ISIN', value: data?.crisin },
                { label: 'Face Value', value: `₹ ${data?.face_value}` },
                { label: 'P/E Ratio', value: data?.pe_ratio },
                { label: 'EPS', value: data?.eps },
                { label: 'P/B Ratio', value: data?.pb_ratio },
                { label: 'Book Value', value: data?.book_value },
                {
                  label: 'Debt to Equity Ratio',
                  value: data?.debt_to_equity_ratio,
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="rounded-lg border bg-white p-4 shadow transition-shadow hover:shadow-lg"
                >
                  <h3 className="font-medium text-gray-700">{item.label}</h3>
                  <p className="text-xl font-semibold text-blue-600">
                    {item.value}
                  </p>
                </div>
              ))}
            </div>
          )}

          {activeOption === 'Key Financials' && (
            <div className="text-center text-gray-600">
              <h2 className="text-2xl font-semibold">Coming Soon...</h2>
            </div>
          )}

          {activeOption === 'Shareholding' && (
            <div className="overflow-x-auto">
              <table className="w-full border-collapse border bg-white text-gray-700 shadow">
                <thead>
                  <tr className="bg-gray-100 text-left">
                    <th className="border px-4 py-2">Name</th>
                    <th className="border px-4 py-2">Holding (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {data?.share_holding?.map((investor, index) => (
                    <tr
                      key={index}
                      className="transition-colors duration-150 hover:bg-gray-50"
                    >
                      <td className="border px-4 py-2">{investor?.name}</td>
                      <td className="border px-4 py-2">
                        {investor['holding%']}
                      </td>
                    </tr>
                  ))} */}
                  {data?.share_holding &&
                    Object.entries(data.share_holding).map(
                      ([key, value], index) => (
                        <tr
                          key={index}
                          className="transition-colors duration-150 hover:bg-gray-50"
                        >
                          <td className="border px-4 py-2">{key}</td>
                          <td className="border px-4 py-2">{value}</td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          )}

          {activeOption === 'Strength & Weaknesses' && (
            <div>
              <h2 className="text-2xl font-bold text-green-500">Strengths</h2>
              <ul className="my-4 list-disc pl-5 text-gray-700">
                {data?.strength_and_weakness?.strength?.map((strength) => (
                  <li key={strength}>{strength}</li>
                ))}
              </ul>

              <h2 className="mt-6 text-2xl font-bold text-red-500">
                Weaknesses
              </h2>
              <ul className="my-4 list-disc pl-5 text-gray-700">
                {data?.strength_and_weakness?.weakness?.map((weakness) => (
                  <li key={weakness}>{weakness}</li>
                ))}
              </ul>
            </div>
          )}

          {activeOption === 'About Company' && (
            <div className="rounded-lg bg-gray-50 p-4 shadow">
              <h2 className="text-lg font-semibold">
                {data?.about_company.details}
              </h2>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

export default CompanyDetail
