import { companyColumnsDataOrders } from './variables/ColumnDataOrders'
import UnlistedCompanyListTable from './components/List'
import Card from 'components/card'
import { useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
const apiService = ApiCaller()
/**
 * Renders the Company List component with the fetched company data.
 *
 * @return {JSX.Element} The rendered Company List component
 */
const UnlistedCompanyList = () => {
  const [allAppData, setCompanyData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [isCompanyLoading, setCompanyLoading] = useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCompanyLoading(true)
        const apiUrl = apiConfig.GET_ALL_UNLISTED_COMPANIES
        const payload = {
          page: currentPage,
          name: searchValue,
          filter: {},
        }
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.status_code === 200) {
          const companyData = response?.data?.data?.data || []
          setCompanyData(companyData)
          setTotalCount(response?.data?.data?.totalCount || 0)
          setCurrentPage(response?.data?.data?.currentPage || 1)
          setItemsPerPage(response?.data?.data?.itemsPerPage || 10)
        } else {
          setCompanyData([])
          setTotalCount(0)
        }
      } catch (error) {
        console.error('Error during API call:', error)
      } finally {
        setCompanyLoading(false)
      }
    }
    fetchData()
  }, [currentPage, searchValue])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const setGlobalFilter = (value) => {
    try {
      setCurrentPage(1)
      setSearchValue(value)
    } catch (err) {
      console.log('err', err)
    } finally {
      setCompanyLoading(false)
    }
  }

  return (
    <>
      <Card extra={'w-full h-full bg-white mt-3 px-4'}>
        <UnlistedCompanyListTable
          tableData={allAppData}
          columnsData={companyColumnsDataOrders}
          totalCount={totalCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isCompanyLoading={isCompanyLoading}
          setCompanyLoading={setCompanyLoading}
          setGlobalFilter={setGlobalFilter}
        />
      </Card>
    </>
  )
}

export default UnlistedCompanyList
