import React, { useMemo, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FiSearch } from 'react-icons/fi'
import { HiPlus } from 'react-icons/hi'
import { MdEdit } from 'react-icons/md'
import TooltipHorizon from 'components/tooltip'
import debounce from 'lodash.debounce'
import webConstants from 'common/config/appConstants'
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'
import PaginationSkeleton from 'components/skeletons/PaginationSkeleton'
/**
 * Renders the search table for orders and handles pagination, filtering, and sorting.
 *
 * @param {Object} props - Component props for the search table.
 * @return {JSX.Element} JSX representation of the search table component.
 */
function SearchTableOrders(props) {
  const { NO_DATA } = webConstants
  let navigate = useNavigate()
  const {
    columnsData,
    tableData,
    totalCount,
    onPageChange,
    itemsPerPage,
    currentPage,
    isCompanyLoading,
    setGlobalFilter,
  } = props

  const columns = useMemo(() => columnsData, [columnsData])

  const [data, setData] = useState([])
  const [totalPageCount, setPageCount] = useState(0)
  const [pageIndex, setPageIndex] = useState(currentPage)

  console.log(data)
  useEffect(() => {
    if (tableData) {
      setData(tableData)
    }
  }, [tableData])

  useEffect(() => {
    if (totalCount) {
      setPageCount(totalCount)
    }
  }, [totalCount])

  useEffect(() => {
    setPageIndex(currentPage)
  }, [currentPage])

  // eslint-disable-next-line
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex, itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  // const tableSkeletons = Array.from({ length: 4 })
  const tableSkeletons = Array.from({ length: itemsPerPage || 4 })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { globalFilter },
    gotoPage,
  } = tableInstance

  const page = tableInstance.rows

  const setFilter = debounce(async (value) => {
    try {
      setGlobalFilter(value)
    } catch (err) {
      console.log('err', err)
    }
  }, 500)

  /**
   * A function to handle the click event for editing a company.
   *
   * @param {Object} data - The data object containing the company details
   * @return {void}
   */
  const handleEditClick = (data) => {
    navigate(`/edit-unlisted-company/${data.id}`)
  }

  /**
   * Updates the page index and navigates to the specified page.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} This function does not return anything.
   */
  // const changePage = (page) => {
  //   setPageIndex(page)
  //   gotoPage(page)
  //   onPageChange(page + 1)
  // }

  const changePage = (page) => {
    onPageChange(page) // This updates `currentPage` in `UnlistedCompanyList`
  }

  const goToPage = (id) => {
    navigate(`/unlisted-companies/company-details/${id}`)
  }

  return (
    <>
      <div>
        <div className="h-full w-full p-2 md:p-4 ">
          {/* Search input and Add New button */}
          <div className="mb-3 flex w-full items-center rounded-full bg-white p-[5px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none">
            <div className="flex h-9 w-full flex-grow items-center rounded-full border-white bg-lightPrimary text-sm text-gray-600 dark:border dark:!bg-navy-900">
              <FiSearch className="mx-2 h-6 w-4 !text-gray-700 dark:!text-white" />
              <input
                type="text"
                placeholder="Search By Name"
                className="placeholder:text-lightFourth block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white"
                value={globalFilter}
                onChange={(e) => setFilter(e?.target?.value)}
              />
            </div>
          </div>
        </div>

        {/* Company cards */}
        <div className="mt-2 grid grid-cols-1 gap-4 pb-16 sm:grid-cols-2 lg:grid-cols-3">
          {isCompanyLoading ? (
            <p className="text-gray-500 dark:text-gray-400">Loading...</p>
          ) : (
            data?.map((company, index) => (
              <div
                key={index}
                onClick={() => goToPage(company?.id)}
                className="cursor-pointer rounded-lg border border-gray-200 bg-white p-4 shadow dark:border-gray-700 dark:bg-navy-800"
              >
                <div className="text-lg font-semibold text-navy-700 dark:text-white">
                  {company.name || 'Name not available'}
                </div>
                <div className="text-sm text-gray-600 dark:text-gray-300">
                  Sector: {company.sector_name || 'N/A'}
                </div>
                <div className="text-sm text-gray-600 dark:text-gray-300">
                  Rate: {company.rate || 'N/A'}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  )
}

export default SearchTableOrders

// return (
//   <>
//     <div className="h-full w-full p-2 md:p-4">
//       {/* Search input */}

//       <div className="flex h-full w-full flex-col items-center justify-between !pt-0 md:flex-row md:py-2">
//         {/* Search input */}
//         <div className="mb-3 flex w-full items-center rounded-full bg-white p-[5px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none">
//           <div className="flex h-9 w-full flex-grow items-center rounded-full border-white bg-lightPrimary text-sm text-gray-600 dark:border dark:!bg-navy-900">
//             <FiSearch className="mx-2 h-6 w-4 !text-gray-700 dark:!text-white" />
//             <input
//               type="text"
//               placeholder="Search By Name"
//               className="placeholder:text-lightFourth block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white"
//               value={globalFilter}
//               onChange={(e) => setFilter(e?.target?.value)}
//             />
//           </div>
//         </div>
//       </div>

//       {/* Table */}

//       {isCompanyLoading ? (
//         <div className="flex flex-col">
//           {tableSkeletons?.map((_item, index) => (
//             <div className="flex animate-pulse border-b border-gray-200 py-4 dark:!border-white/10">
//               <div className="w-1/5">
//                 <div className="ml-4 h-2 w-1/6 rounded bg-gray-200 dark:bg-gray-800"></div>
//               </div>
//               <div className="w-2/5">
//                 <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
//               </div>
//               <div className="w-2/5">
//                 <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
//               </div>
//               <div className="w-2/5">
//                 <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
//               </div>
//               <div className="w-1/5">
//                 <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
//               </div>
//             </div>
//           ))}
//         </div>
//       ) : (
//         <div className="mt-2 min-h-[100px] w-full overflow-x-scroll xl:overflow-hidden">
//           <table {...getTableProps()} className="w-full">
//             <thead className="w-full">
//               {headerGroups?.map((headerGroup, indexRow) => (
//                 <tr
//                   className="items-center border-b border-gray-200 dark:!border-white/10"
//                   {...headerGroup?.getHeaderGroupProps()}
//                   key={indexRow}
//                 >
//                   {headerGroup?.headers?.map((column, index) => {
//                     return (
//                       <th
//                         {...column?.getHeaderProps(
//                           column?.getSortByToggleProps()
//                         )}
//                         className="px-4 pb-[10px] text-left text-xs text-gray-600"
//                         key={index}
//                       >
//                         <div className="flex items-center">
//                           {column?.render('Header')}
//                           {column?.isSorted ? (
//                             column?.isSortedDesc ? (
//                               <BsArrowDown className="ml-2" />
//                             ) : (
//                               <BsArrowUp className="ml-2" />
//                             )
//                           ) : (
//                             ''
//                           )}
//                         </div>
//                       </th>
//                     )
//                   })}
//                 </tr>
//               ))}
//             </thead>
//             <tbody {...getTableBodyProps()}>
//               {page?.map((row, rowIndex) => {
//                 prepareRow(row)
//                 return (
//                   <tr
//                     className="border-b border-gray-200 dark:!border-white/10"
//                     {...row?.getRowProps()}
//                     key={rowIndex}
//                   >
//                     {row?.cells?.map((cell, cellIndex) => {
//                       let data = ''
//                       if (
//                         cell.column.Header === 'Name' ||
//                         cell.column.Header === 'Sector' ||
//                         cell.column.Header === 'Price' ||
//                         cell.column.Header === 'Short Name'
//                       ) {
//                         data = (
//                           <div className="text-left font-medium text-navy-700 dark:text-white">
//                             {cell?.value}
//                           </div>
//                         )
//                       } else if (cell?.column?.Header === 'S.No') {
//                         data = (
//                           <div className="text-left font-medium text-navy-700 dark:text-white">
//                             {(pageIndex - 1) * itemsPerPage + rowIndex + 1}
//                           </div>
//                         )
//                       }
//                       return (
//                         <td
//                           className="px-4"
//                           {...cell?.getCellProps()}
//                           key={cellIndex}
//                         >
//                           {data}
//                         </td>
//                       )
//                     })}
//                   </tr>
//                 )
//               })}
//             </tbody>
//           </table>
//           {/* Display message if no data */}
//           {!isCompanyLoading && page?.length === 0 && (
//             <div className="text-md mt-3 flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
//               {NO_DATA}
//             </div>
//           )}
//         </div>
//       )}

//       {/* Pagination */}
//       {isCompanyLoading ? (
//         <PaginationSkeleton />
//       ) : (
//         page?.length > 0 && (
//           <div className="mt-2 flex h-12 w-full items-center justify-between px-6">
//             <div className="text-sm text-gray-600">
//               {/* Display current range of entries */}
//               Showing {pageIndex * itemsPerPage - itemsPerPage + 1} -{' '}
//               {totalPageCount < pageIndex * itemsPerPage
//                 ? totalPageCount
//                 : pageIndex * itemsPerPage}{' '}
//               of {totalPageCount}
//             </div>

//             <div className="flex items-center">
//               {/* Render pagination buttons */}
//               <div className="ml-3 flex">
//                 {/* Previous button */}
//                 <button
//                   className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
//                     pageIndex === 1 ? 'cursor-not-allowed opacity-50' : ''
//                   }`}
//                   onClick={() => changePage(pageIndex - 1)}
//                   disabled={pageIndex === 1}
//                 >
//                   {'<'}
//                 </button>
//                 {/* Next button */}
//                 <button
//                   className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
//                     pageIndex >= Math.ceil(totalPageCount / itemsPerPage)
// ? 'cursor-not-allowed opacity-50'
//                       : ''
//                   }`}
//                   onClick={() => changePage(pageIndex + 1)}
//                   disabled={
//                     pageIndex >= Math.ceil(totalPageCount / itemsPerPage)
//                   }
//                 >
//                   {'>'}
//                 </button>
//               </div>
//             </div>
//           </div>
//         )
//       )}
//     </div>
//   </>
// )

{
  /* Pagination */
}
{
  /* {data.length > 0 && (
          <div className="mt-2 flex h-12 w-full items-center justify-between px-6">
            <div className="text-sm text-gray-600">

              Showing {pageIndex * itemsPerPage + 1} -{' '}
              {totalPageCount < pageIndex * itemsPerPage + data.length
                ? totalPageCount
                : pageIndex * itemsPerPage + data.length}{' '}
              of {totalPageCount}
            </div>
            <div className="flex items-center">
              <div className="ml-3 flex">

                <button
                  className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-700 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                    pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                  }`}
                  onClick={() => changePage(pageIndex - 1)}
                  disabled={pageIndex === 0}
                >
                  {'<'}
                </button>

                <button
                  className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                    pageIndex >= Math.ceil(totalPageCount / itemsPerPage) - 1
                      ? 'cursor-not-allowed opacity-50'
                      : ''
                  }`}
                  onClick={() => changePage(pageIndex + 1)}
                  disabled={
                    pageIndex >= Math.ceil(totalPageCount / itemsPerPage) - 1
                  }
                >
                  {'>'}
                </button>
              </div>
            </div>
          </div>
        )} */
}
