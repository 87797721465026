import defaultImage from 'assets/img/others/default-image.png'
const webConstants = {
  TOAST: {
    MESSAGES: {
      ERROR: {
        fetchBranchError: 'Unable to fetch branches',
        fetchVersionError: 'Unable to fetch versions',
        fetchRepoError: 'Unable to fetch Repositories',
        cwsError: 'Web Service File already exist',
        workflowError: 'Failed to get workflow',
        getAllEventsError: 'Unable to fetch all Events',
        getDeploymentLogsError: 'Failed to get depolyment logs',
        swwError: 'Something went wrong.',
        getBranchError: 'No repository selected. Aborting API call.',
        noRepoError: 'No repository selected.',
        pgrError: 'Please select any one repository',
        gitTokenError: 'Invalid Github token',
        argoCdTokenError: 'Invalid argoCd token',
        artifactNotAddedError: 'No artifacts added, Please add one',
        artifactNotAddedError1: 'Please add artifact repository to proceed',
        createProjectError: 'Error occured while creating Project',
        rerunJobsError: 'Failed to re-run job',
        cancelJobsError: 'Failed to cancel job',
      },
      SUCCESS: {
        cwsSuccess: 'Web service created successfully',
        addedIssue: 'Issue added successfully',
        labelAdded: 'Label added successfully',
        updatedIssue: 'Issue updated successfully',
        artifactRepositorySuccess: 'Artifact repository created successfully',
        artifactUpdateSuccess: 'Artifact repository updated successfully',
        signInSuccess: 'Logged in successfully',
        logoutSuccess: 'Logged out successfully',
        gitSuccess: 'Git token updated successfully',
        argoSuccess: 'ArgoCD token updated successfully',
        organizationCreated: 'Organization created successfully',
        organizationEdited: 'Organization edited successfully',
        deletedData: 'Data has been deleted successfully',
        rerunJobs: 'Job re-run successfully',
        cancelJobs: 'Job cancelled successfully',
      },
    },
  },
  VALIDATION: {
    CHECKING_AVAILABILITY: 'Checking availability...',
  },
  LABEL: {
    ORGANIZATION_NAME: 'Organization Name',
    USER_NAME: 'User Name',
    REPOSITORY_NAME: 'Repository Name',
    GITHUB_TOKEN: 'Github Token',
    GITHUB_USER: 'Github User',
    BRANCH_NAME: 'Branches Name',
    ENVIRONMENT_NAME: 'Environment Name',
    UPDATE_LABEL: 'Update',
    CREATE_LABEL: 'Create',
    ADD_LABEL: 'Add',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    LABELS: 'Labels',
    NEW_LABEL: 'New Label',
    LABEL_NAME: 'Label Name',
    COLOR: 'Color',
    DEFAULT_COLOR_TXT: 'Choose from default colors:',
    APP_NAME: 'Application Name',
    APP_NAME_SUB_LBL: 'A unique name for your service',
    BRANCH: 'Branch',
    HEAD_BRANCH: 'Head Branch',
    BRANCH_SUB_LBL: 'The Repository branch used for your service',
    PORT: 'Port',
    PORT_SUB_LBL: 'The port is allotted to every application.',
    SCHEDULE: 'Schedule',
    SCHEDULE_SUB_LBL: 'The schedule for the command as a cron expression.',
    COMMAND: 'Command',
    COMMAND_SUB_LBL: 'The command to run periodically.',
    STATUS: 'Status',
    TOTAL_DUR: 'Total duration',
    BILLABLE_TIME: 'Billable time',
    ARTIFACTS: 'Artifacts',
    KUBERNETES_CLUSTER: 'Kubernetes cluster',
    CREDENTIALS_TYPE: 'Credentials Type',
    NAME: 'Name',
    TOP_LEVEL_DOMAIN_MANAGEMENT: 'Top-level domain management',
    DNS_PROVIDER: 'DNS Provider',
    APPLICATION_KEY: 'Application Key',
    APPLICATION_SECRET: 'Application Secret',
    G_CLOUD: 'Google Cloud',
    AWS_CLOUD: 'Amazon Web Services',
    PROJECT_ID: 'Project ID',
    ACCESS_KEY_ID: 'Access Key ID',
    SECRET_KEY_ID: 'Secret Key ID',
    ARTIFACT_PATH: 'Artifact Path',
    GITHUB_ID: 'Users Github User ID',
    REGION: 'Region',
    G_SERVICE_KEY: 'Google Service key',
    AWS_SERVICE_KEY: 'AWS Service key',
    SERVER: 'Server',
    CLUSTER_NAME: 'Cluster Nanme',
    DOMAIN_NAME: 'Domain Name',
    ARTIFACTS_REPOSITORY_NAME: 'Artifact Repository Name',
    VERSION: 'Version',
    MAPPING_HEADING: 'Mapping',
    MAPPING_SUB_LBL: 'Map your domain name with the corresponding branch.',
    MAPPING_ARTIFACT_LBL:
      'Map your domain name and artifact repository name with the corresponding branch.',
    KUBERNETES_TOKEN_LBL: 'Kubernetes Token',
    ADD_ENVIRONMENT: 'Add Environment',
    UPDATE_DOMAIN: 'Update Domain',
    ENVIRONMENT: 'Environment',
    CREATE_ENVIRONMENT: 'Create Environment',
    PROJECT_NAME: 'Project Name',
  },
  MAX_LENGTH: {
    APP_NAME: 50,
    application_maxlengthError:
      'Application name cannot be more than app_maxLength characters',
  },
  WEB_SERVICES: {
    EVENTS: 'Events',
    DEPLOYMENT_LOGS: 'Deployment Logs',
    CONFIG_MAP: 'ConfigMaps',
    SECRETS: 'Secrets',
    DOMAIN_MANAGEMENT: 'Environment Management',
  },
  Billing: {
    BILLING_DETAILS: 'Billing Information',
    BILLING_TABS_TEXT: 'Plan Details',
  },
  ALLVEST: {
    CONFIXA_HEADER: 'ALLVEST',
  },
  REQUIRED: {
    GIT_REQUIRED: 'Git token required',
    ARGO_CD_REQUIRED: 'ArgoCD user API token required',
    SELECT_REPOSITORY_REQUIRED: 'Please select repository',
    DOCKER_REQUIRED:
      'The Docker file is already available in this repository.If you wish to edit the file click on the cross below and replace the file.',
    branchRequired: 'At least one branch name is required',
    organizationRequired: 'Organization name is required',
    titleRequired: 'title is required',
    RepositoryRequired: 'Repository name is required',
    ENVIRONMENTS_REQUIRED: 'Environment name is required',
  },
  BUTTON_TEXT: {
    EDIT: 'Edit',
    VERIFY: 'Verify',
    RENEW: ' Renew',
    CONNECT: 'Connect',
    UPDATE: 'Update',
    DOWNLOAD: 'Download',
    REPOSITORY: 'Repository',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    NEXT: 'Next',
    GITHUB: 'Github',
    PUBLIC: 'Public',
    PRIVATE: 'Private',
    BACK: 'Back',
    CREATE_SERVICE: 'create service',
    ADD: 'Add',
    SAVE_CHNG: 'Save Changes',
    UPDATES: 'Updates',
    RERUN_JOB: 'Re-run Job',
    CANCEL_JOB: 'Cancel Job',
    NEW_WEB_SERVICES: 'New Web Services',
    NEW_STATIC_SITES: 'New Static Sites',
    NEW_PRIVATE_SERVICES: 'New Private Services',
    NEW_BACKGROUND_WORKERS: 'New Background Workers',
    NEW_CRON_JOBS: 'New Cron Jobs',
    NEW_POSTGRE_SQL: 'New PostgreSQL',
    NEW_REDIS: 'New Redis',
    NEW_BLUE_PRINTS: 'New Blueprints',
    NEW_LABEL: 'New Label',
    SAVE_CHANGES: 'Save Changes',
    CHECK_PR: 'Check PR',
    NEW_CHAT: 'New Chat ',
    ACTIVE: 'Active',
    PREVIOUS: 'Previous',
    GITHUB_ACCOUNT: 'Github Account',
    ARTIFACT_REPOSITORY: 'Artifact Repository',
    COMING_SOON: 'Coming Soon',
    ORGANIZATION: 'Organization',
    USER: 'User',
  },
  SIGNIN: {
    WELCOMETEXT: `Welcome to ALLVEST`,
    SIGNINBUTTONTEXT: 'Click here to Sign In',
    LEARNMORE: 'Learn more about ALLVEST on',
    DOMAIN: 'www.ALLVEST.com',
  },
  SUBSCRIPTION: {
    PLANS: `Plan / Duration`,
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    EXPIRING: 'Expiring By',
    LICENSE_KEY: 'License Key',
    DAYS_REMAINING: 'days remaining',
    COPY: 'Copied!',
  },
  SUBSCRIPTION_EXPIRED: {
    TITLE: 'Your subscription has expired',
    END_TITLE: 'Subscription Expired',
    EXPIRED_ON: 'on',
    EXPIRED_TITLE: "We're Sorry to See You Leave",
    EXPIRED_MESSAGE:
      'Your subscription has expired, and access to our premium features has been disabled. To maintain uninterrupted access to our exclusive content, tools, and services, please renew your subscription by reaching out to us at',
    RENEW_MESSAGE: 'Renew Your Subscription Now: ',
    CONNECTED_MESSAGE:
      'Stay connected and make the most of our premium offerings.',
    NOTE: 'For any questions or help with renewing your subscription, please contact us at',
    SUBSCRIPTION_NOTE:
      'If you have any questions or need assistance with renewing your subscription,',
    SUBSCRIPTION_CONTACT_NOTE: 'please contact',
    CONTACT_EMAIL: 'contact@ALLVEST.com',
    SUBSCRIPTION_ALERT: 'Subscription Alert',
    RENEW_TOPIC: 'Please renew to continue enjoying our services.',
    SUBSCRIPTION_END_TOPIC: 'Your subscription is about to end.',
    SUBSCRIPTION_DAYS_TOPIC: 'days to go!',
    SUBSCRIPTION_EXPIRING: 'Expiring in',
    DAYS: 'days',
  },
  EMAIL: {
    SUBJECT: 'Request for ALLVEST Reactivation of Expired Subscription',
    BODY: `Dear ALLVEST Support Team,

I hope this message finds you well. I am writing to request the activation of ALLVEST services for my domain "frontend.ALLVEST.com" and to reactivate the expired subscription associated with it. My subscription, which was on the Base plan and billed annually, expired on {deactivateDate}.

Here are the details of my subscription:

Domain Name: frontend.ALLVEST.com
Plan: Base (Annual)
Activated On: {activateDate}
Deactivated On: {deactivateDate}

I would appreciate your assistance in reactivating the subscription and ensuring that ALLVEST services are active for the domain. Please let me know the steps required to proceed, including any payment details or additional information you may need from my end.

Thank you for your support.`,
  },

  SUBMIT_TICKET_TYPES: [
    { label: 'Question', value: 'Question' },
    { label: 'Incident', value: 'Incident' },
    { label: 'Problem', value: 'Problem' },
    { label: 'Feature Request', value: 'Feature Request' },
    { label: 'Refund', value: 'Refund' },
  ],

  SUPPORT_PRIORITY_LIST: [
    { label: 'Low', value: 'Low', priorityValue: 1 },
    { label: 'Medium', value: 'Medium', priorityValue: 2 },
    { label: 'High', value: 'High', priorityValue: 3 },
    { label: 'Urgent', value: 'Urgent', priorityValue: 4 },
  ],
  SUPPORT_STATUS_LIST: [
    { label: 'All Status', value: 'All Status', defaultValues: true },
    { label: 'Open', value: 'Open', statusValue: 2 },
    { label: 'Pending', value: 'Pending', statusValue: 3 },
    { label: 'Resolved', value: 'Resolved', statusValue: 4 },
    { label: 'Closed', value: 'Closed', statusValue: 5 },
  ],
  SUPPORT_SORTBY_LIST: [
    { label: 'Date Created', value: 'Date Created' },
    { label: 'Last Modified', value: 'Last Modified' },
    { label: 'Priority', value: 'Priority' },
    { label: 'Status', value: 'Status' },
    { divider: true }, // Add this for the separator
    { label: 'Ascending', value: 'Ascending' },
    { label: 'Descending', value: 'Descending' },
  ],
  NEW_WEB_SERVICES: {
    SELECT_REPOSITORY_TEXT: 'Select Your Repository',
    GITHUB_HEADER_PARAGRAPH:
      'Connect your ALLVEST account to Github to being using your existing repos for new services',
    PUBLIC_REPOSITORY_HEADER_TEXT: 'Public Git Repository',
    PUBLIC_REPOSITORY_HEADER_PARAGRAPH:
      'Please provide the URL of the public repository you wish to utilize for building services with your ALLVEST account.',
    PRIVATE_REPOSITORY_HEADER_TEXT: 'Private Git Repository',
    PRIVATE_REPOSITORY_HEADER_PARAGRAPH:
      'Please select a private repository connected to your ALLVEST account for building your service.',
  },
  DEPLOYING_WEB_SERVICE: {
    DEPLOYING_WEB_SERVICE_HEADER: 'You are deploying a :service service',
  },
  HOME: {
    HOME_TEXT: 'Quick Start : Deploy in minutes',
  },
  GIT: {
    GIT_HEADER: 'Github Token',
    GIT_HEADER_PARAGRAPH:
      'A GitHub token is a secure access key used for authenticating API requests, enabling seamless interaction with GitHub. It allows you to access repositories, manage issues, and automate workflows. To create a non-expiring token, go to your GitHub settings, generate a personal access token, and select all necessary scopes. Always keep your token private to ensure the security of your projects and data.',
    GIT_URL: 'How to get GitHub token',
  },
  ARGO_CD: {
    ARGO_CD_HEADER: 'ArgoCD User Api Token',
    ARGO_CD_HEADER_PARAGRAPH:
      'Use a Public Repository by entering the URL below. Features like PR Reviews and Auto-Deploy are not available if repository has not been configured for render',
    ARGO_CD_URL: 'How to get ArgoCd token',
  },
  ARTIFACT_REPOSITORY: {
    ARTIFACT_REPOSITORY_URL: 'How to create Artifact Repositories',
  },
  APPLICATION_CREATE_STEPS: {
    FIRST_STEP: 0,
    SECOND_STEP: 1,
    THIRD_STEP: 2,
  },
  STEPPER_SERIVCE: {
    NEXTSTEP: 'Next',
    PREVIOUSSTEP: 'Previous',
    KEY_LABEL: 'Key',
    VALUE_LABEL: 'value',
  },
  STEPPER_TITLE: [
    { title: 'Application' },
    { title: 'Environment Management' },
    { title: 'ConfigMaps' },
  ],
  LOADER_TEXTS: [
    'Generating response.',
    'Processing your query.',
    'Analyzing your request.',
    'Fetching relevant information.',
    'Executing command.',
    'Compiling data for your request.',
    'Processing your command.',
  ],

  NOTE: {
    KEBAB_CASE:
      'Note : Required name in Kebab case or it will convert automatically. (Example - "ConfixaProject convert it into ALLVEST-project")',
    UPDATE_VALUES: 'Please update the values for the respective',
  },
  HEY: 'Hey,',
  NO_DATA_AVAIL: 'No Data Available',
  TABS: {
    APP: 'Applications',
    HELM: 'Helm Chart',
    ISSUES: 'Issues',
    VALUES: 'Values',
    DOMAIN_MGMT: 'Environment Management',
    EVENTS: 'Events',
    DEPLOY_LOGS: 'Deployment Logs',
    CONFIG_MAP: 'ConfigMaps',
    SECRETS: 'Secrets',
    DETAILS: 'Details',
    BRANCHES: 'Branches',
    BRANCH: 'Branch',
    HELM_CHART_TAB: 'helm_chart',
    BRANCH_DETAIL: 'branch_detail',
    BRANCH_LIST: 'branch_list',
    ENVIRONMENTS: 'Environments',
    ENVIRONMENT_LIST: 'environments_list',
  },
  TABLE_HEADER: {
    SR_NO: 'S.NO',
    ISSUES: 'ISSUES',
    AUTHOR: 'AUTHOR',
    PR_STATUS: 'PR STATUS',
    ACTION: 'ACTION',
    BRANCH_NAME: 'BRANCH NAME',
    ENVIRONMENT_NAME: 'ENVIRONMENT NAME',
    KUBERNETES_CLUSTER: 'KUBERNETES CLUSTER',
    TOP_LEVEL_DOMAIN: 'TOP-LEVEL DOMAIN',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
  },
  NO_DATA: 'No Data Available',
  PREVIOUS_CHATS_NOT_AVAILABLE: 'Previous chats are not available.',
  PLACE_HOLDER: {
    SEND_A_MESSAGE: 'Send a message to the Assistant',
  },
  BUILD_DEPLOY_GIT_REPOSITORY: 'Build and deploy from a git repository',
  CONNECT_GITHUB_REPOSITORY: 'Connect a Github repository',
  WORK_FLOW_STATUS: [
    'cancelled',
    'completed',
    'success',
    'failure',
    'timed_out',
    'skipped',
    'startup_failure',
  ],
  WORK_FLOW_PROCESS_STATUS: ['queued', 'in_progress', 'waiting'],
  ISSUE_STATUS: {
    inProcess: 'Process',
    open: 'Open',
    close: 'Close',
    failed: 'Failed',
    merge: 'Merged',
  },

  URL_CONFIGURATION: {
    SYSTEM_HEALTH_URL: process.env.REACT_APP_UPTIME_URL,
    SYSTEM_HEALTH_TITLE: 'System Health Monitoring',
    SYSTEM_HEALTH_INFO:
      'Set up system health monitoring to track uptime, response times, and resource usage. Real-time alerts help you resolve issues before they cause downtime, ensuring reliable service delivery. This tool enhances operational efficiency and keeps your systems running smoothly.',
    SYSTEM_HEALTH_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL +
        process.env.REACT_APP_SYSTEM_HEALTH_IMG
      : defaultImage,

    FINOPS_URL: process.env.REACT_APP_KUBECOST_URL,
    FINOPS_TITLE: 'FinOps for Kubernetes',
    FINOPS_INFO:
      'Implement FinOps to optimize cloud spending in Kubernetes. Gain visibility into resource usage and costs, avoid over-provisioning, and manage cloud expenses more effectively. Setting up FinOps ensures financial accountability while maximizing resource efficiency in your cloud environment.',
    FINOPS_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_KUBECOST_IMG
      : defaultImage,

    CLUSTER_URL: process.env.REACT_APP_KUBERNETES_DASHBOARD_URL,
    CLUSTER_TITLE: 'Cluster Management Dashboard',
    CLUSTER_INFO:
      'Set up a cluster management dashboard to gain real-time insights into your Kubernetes clusters. Monitor nodes, resource usage, and workloads in one place. Simplify troubleshooting and scaling to keep your clusters running efficiently and reliably.',
    CLUSTER_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL +
        process.env.REACT_APP_CLUSTER_DASHBOARD_IMG
      : defaultImage,

    LOG_MONITORING_URL: process.env.REACT_APP_ELASTIC_URL
      ? process.env.REACT_APP_ELASTIC_URL + process.env.REACT_APP_LOGS_URI
      : '',
    LOG_MONITORING_TITLE: 'Log Monitoring',
    LOG_MONITORING_INFO:
      'Centralize your log data to easily track errors and system events. Log monitoring helps you debug issues, ensure compliance, and maintain system reliability. Set it up for comprehensive operational visibility across your infrastructure.',
    LOG_MONITORING_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_LOGS_IMG
      : defaultImage,

    METRICS_MONITORING_URL: process.env.REACT_APP_ELASTIC_URL
      ? process.env.REACT_APP_ELASTIC_URL + process.env.REACT_APP_METRICS_URI
      : '',
    METRICS_MONITORING_TITLE: 'Metrics Monitoring',
    METRICS_MONITORING_INFO:
      'Set up metrics monitoring to track key performance indicators like CPU, memory, and network usage. Detect trends, optimize resource allocation, and prevent performance bottlenecks to maintain high-performing systems.',
    METRICS_MONITORING_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_METRICS_IMG
      : defaultImage,

    TRACING_FOR_SYSTEMS_URL: process.env.REACT_APP_ELASTIC_URL
      ? process.env.REACT_APP_ELASTIC_URL + process.env.REACT_APP_TRACE_URI
      : '',
    TRACING_FOR_SYSTEMS_TITLE: 'Tracing for Distributed Systems',
    TRACING_FOR_SYSTEMS_INFO:
      'Implement tracing to visualize request flows across distributed systems. Tracing helps you identify performance bottlenecks and optimize microservices. Set it up for better troubleshooting and faster resolution of complex issues.',
    TRACING_FOR_SYSTEMS_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_TRACE_IMG
      : defaultImage,

    CONTINUOUS_DELIVERY_URL: process.env.REACT_APP_ARGOCD_URL,
    CONTINUOUS_DELIVERY_TITLE: 'Continuous Delivery for Kubernetes',
    CONTINUOUS_DELIVERY_INFO:
      'Automate your Kubernetes deployments with continuous delivery. Set it up to streamline updates, reduce manual errors, and speed up releases. Ensure faster rollbacks and version control for improved application reliability.',
    CONTINUOUS_DELIVERY_IMG: process.env.REACT_APP_IMAGE_URL
      ? process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_ARGOCD_IMG
      : defaultImage,
  },

  GITHUB_URL: 'https://github.com/',
  TOOLTIPS: {
    SCHEDULE_INFO: `<div class="font-bold">Specify tasks using five fields:</div>
    <div>Minute (0-59), Hour (0-23), Day of Month (1-31), Month (1-12), Day of Week (0-7, Sun=0/7).</div>
    <div class="font-bold mt-3">For example:</div>
    <div>
      <ul>
        <li>0 0 * * * - Daily at midnight</li>
        <li>30 14 1 * * - 2:30 PM on the 1st</li>
        <li>15 10 * * 1-5 - 10:15 AM, Mon-Fri</li>
      </ul>
    </div>`,
    ARTIFACTS_INFO: `
    <div class="font-bold">GCP:</div>
    <div class="font-bold">asia-south1-docker.pkg.dev/demo-rnd/demo-applications refers to a Google Cloud Platform (GCP) Artifact Registry repository:</div>
    <div>
      <ul>
        <li><b>Region:</b> asia-south1</li>
        <li><b>Service:</b> docker.pkg.dev (Docker images)</li>
        <li><b>Project:</b> demo-rnd</li>
        <li><b>Repository:</b> demo-applications</li>
      </ul>
    </div>
    <div class="font-bold mt-4">AWS:</div>
    <div class="font-bold">533267415085.dkr.ecr.eu-west-2.amazonaws.com is an AWS Elastic Container Registry (ECR) path:</div>
    <div>
      <ul>
        <li><b>Account ID:</b> 533267415085</li>
        <li><b>Service:</b> dkr.ecr (Docker ECR)</li>
        <li><b>Region:</b> eu-west-2 (Europe, London)</li>
        <li><b>Domain:</b> amazonaws.com</li>
      </ul>
    </div>
    <div class="font-bold mt-4">This path is used to store and manage Docker container images.</div>
`,
  },
}

export default webConstants
