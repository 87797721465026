import axios from 'axios'

const handle401Error = () => {
  localStorage.clear()
  // window.location.href = '/sign-in';
}

const ApiCaller = () => {
  const apiCall = async (httpType, url, data, options) => {
    let headers
    if (localStorage.getItem('accessToken')) {
      headers = {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    }
    try {
      let response
      switch (httpType.trim().toLowerCase()) {
        case 'get':
          response = await axios.get(url, { headers })
          break
        case 'post':
          response = await axios.post(url, data, { headers })
          break
        case 'put':
          response = await axios.put(url, data, { ...options, headers })
          break
        case 'delete':
          response = await axios.delete(url, { ...options, headers, data })
          break
        default:
          throw new Error('Invalid HTTP method provided')
      }
      if (response?.data?.code === 401) {
        handle401Error()
      }
      return response
    } catch (error) {
      if (error?.response?.status === 401) {
        handle401Error()
      }
      return error.response
    }
  }

  return { apiCall }
}

export default ApiCaller
