import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Card from 'components/card'

const UrlConfigurationInfoComponent = ({ url, title, text, img }) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (url) {
      window.open(url, '_blank')
      navigate(`/`)
    }
  }, [url])

  return (
    <Card extra={'w-full h-full bg-white mt-3 px-4'}>
      {!url && (
        <div>
          <div className="mt-[25px]">
            <p className="px-4 text-xl font-bold text-navy-700 dark:text-white">
              {title}
            </p>
          </div>
          <div className="px-4 pt-2">
            <div className="text-lg">
              <h1>{text}</h1>
            </div>
          </div>
          <div className="align-items-center flex justify-center py-8">
            <img className="w-[700px]" src={img} alt="Url configurataion" />
          </div>
        </div>
      )}
    </Card>
  )
}

export default UrlConfigurationInfoComponent
