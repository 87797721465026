import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { FaRegTrashCan } from 'react-icons/fa6'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { decryptFunction } from 'common/commonFunction'
import DeleteConfirmationDialog from 'common/DeleteConfirmationDialog'
import SwitchWithText from 'components/switch-with-text'
import appConstants from 'common/config/appConstants'

const { BUTTON_TEXT, PREVIOUS_CHATS_NOT_AVAILABLE } = appConstants
/**
 * Renders a component that displays previously saved data.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - previousData: An array of previously saved data.
 *   - getNewPreviouslyInsideData: A function to get new previously inside data.
 *   - AllTopicsPreviousData: An array of all topics' previously saved data.
 *   - getDeleteData: A function to delete previously saved data.
 *   - isNewChat: A boolean indicating if it's a new chat.
 *   - PreviouslyInsideData: The previously saved data inside.
 *   - buttonClick: A boolean indicating if a button was clicked.
 * @return {JSX.Element} The rendered component.
 */
function PreviouslyData({
  previousData,
  getNewPreviouslyInsideData,
  getDeleteData,
  isNewChat,
  buttonClick,
  IsFullChatOpen,
}) {
  const { id } = useParams()
  let navigate = useNavigate()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [selectedId, SetSelectedId] = useState()
  const [cardData, setCardData] = useState([])
  const [selectedTopic, setSelectedTopic] = useState(null)
  const location = useLocation()
  const [isVisible, setIsVisible] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)

  /**
   * Perform delete operation here
   *
   * @param {}
   * @return {}
   */
  const handleDelete = () => {
    // Perform delete operation here
    handleDeleteClick(selectedId)
    setIsDeleteDialogOpen(false) // Close the dialog after delete
  }
  /**
   * Closes the delete dialog without performing any delete operation.
   *
   * @return {void}
   */
  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false) // Close the dialog without deleting
  }

  useEffect(() => {
    setCardData(previousData)
    const topicId = new URLSearchParams(location.search).get('id')
    if (buttonClick) {
      setSelectedTopic(null)
    } else if (topicId) {
      try {
        const decryptedTopicId = decryptFunction(topicId)
        const topicIdInt = parseInt(decryptedTopicId)
        setSelectedTopic(topicIdInt)
      } catch (error) {
        console.error('Error decrypting topic ID:', error)
      }
    }
    // eslint-disable-next-line
  }, [previousData, buttonClick])

  useEffect(() => {
    if (IsFullChatOpen) {
      setIsVisible(false)
    }
  }, [IsFullChatOpen])

  useEffect(() => {
    const value = location.pathname.includes('/chatbot')
    if (value) {
      setIsVisible(true)
      setFullScreen(true)
      handleCardClick(id)
    } else {
      setFullScreen(false)
    }
  }, [id])

  /**
   * Handles the click event on a card and performs certain actions based on the topic ID.
   *
   * @param {string} topic_id - The ID of the topic associated with the clicked card.
   * @return {void} This function does not return a value.
   */
  const handleCardClick = (_id) => {
    isNewChat(false)
    if (fullScreen) {
      _id ? navigate(`/chatbot/${_id}`) : navigate(`/chatbot`)
    }
    if (selectedTopic === _id) {
      return
    }
    getNewPreviouslyInsideData(_id)
    setSelectedTopic(_id)
    const value = location.pathname.includes('/chatbot')
    if (!value) {
      setIsVisible(false)
    }
  }

  /**
   * Handles the click event for the delete button.
   *
   * @param {string} _id - The ID of the topic to be deleted.
   * @return {void} This function does not return a value.
   */
  const handleDeleteClick = (_id) => {
    getDeleteData(_id)
  }

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  return (
    <div
      className={`transition-max-height relative w-full overflow-hidden duration-300 ease-in-out`}
    >
      <div className={` w-full `}>
        {!fullScreen && (
          <SwitchWithText
            textLeft={BUTTON_TEXT.ACTIVE}
            textRight={BUTTON_TEXT.PREVIOUS}
            checked={isVisible}
            onChange={toggleVisibility}
          />
        )}
        <div>
          {isVisible && (
            <div
              className={`z-23 ${
                isVisible ? 'translate-x-0' : 'translate-x-full'
              } ${
                fullScreen
                  ? 'bottom-20 right-8 top-[100px] h-[70vh] rounded-[30px]'
                  : 'fixed bottom-0 right-0 top-[60px] w-full max-w-96 transform grid-cols-7 rounded-md shadow-lg transition-transform'
              } -mt-2 mt-0 flex  flex-col bg-white p-2 duration-300 ease-in-out dark:bg-navy-800`}
            >
              {fullScreen && (
                <div className="p-2 font-bold text-brand-500 dark:text-brand-400">
                  PREVIOUSLY
                </div>
              )}
              <Scrollbars autoHide>
                {cardData.length > 0 &&
                  cardData.map((item, index) => {
                    const isSelected = selectedTopic === item._id
                    return (
                      <div
                        className={` flex items-center justify-between rounded-lg ${
                          isSelected ? 'bg-lightPrimary' : 'hover:underline'
                        }`}
                        key={index}
                      >
                        <div
                          className={`ml-3 pt-1 text-lg font-semibold dark:text-white
                            
                          ${
                            isSelected ? 'text-black' : 'dark:text-black'
                          }  pb-3  pt-1`}
                        >
                          <div
                            className={`${
                              isSelected ? 'text-black' : ''
                            } cursor-pointer pt-1 text-sm`}
                            onClick={() => handleCardClick(item._id)}
                          >
                            {item.topic}
                          </div>
                        </div>
                        <div className="z-50">
                          <FaRegTrashCan
                            onClick={() => {
                              setIsDeleteDialogOpen(true)
                              SetSelectedId(item._id)
                            }}
                            className={`mr-3 cursor-pointer ${
                              isSelected
                                ? '  text-red-400'
                                : 'text-red-500 hover:underline'
                            }`}
                          />
                        </div>
                        <DeleteConfirmationDialog
                          isOpen={isDeleteDialogOpen}
                          onCancel={handleCancelDelete}
                          onConfirm={handleDelete}
                        />
                      </div>
                    )
                  })}
                {cardData.length === 0 && (
                  <div className="text-md mt-3 text-center font-bold dark:text-white">
                    {PREVIOUS_CHATS_NOT_AVAILABLE}
                  </div>
                )}
              </Scrollbars>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PreviouslyData
