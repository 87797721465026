import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FcGoogle } from 'react-icons/fc'
import InputField from '../../../components/fields/InputField'
import Checkbox from '../../../components/checkbox'
import authImg from '../../../assets/img/others/all-vest-white.png'

import { useSpinner } from '../../../common/SpinnerLoader'
import { useToaster } from '../../../common/Toaster'
import { useNavigate } from 'react-router-dom'
import appConstants from '../../../common/config/appConstants'
import ApiCaller from '../../../common/services/apiServices'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import apiConfig from 'common/config/apiConfig'
const { TOAST } = appConstants
const apiService = ApiCaller()

const SignUp = () => {
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onSubmit', // Validates on change
  })

  let navigate = useNavigate()

  const toSignIn = () => {
    navigate('/sign-in')
  }

  const onSubmit = async (data, event) => {
    event.preventDefault()
    showSpinner()
    try {
      const payload = {
        email: data.email,
        firstName: data.firstName,
        username: data.email,
        lastName: data.lastName,
        emailVerified: true,
        enabled: true,
        credentials: [
          {
            type: 'password',
            value: data.password,
            temporary: false,
          },
        ],
      }
      console.log(payload)
      const apiUrl = apiConfig.USER_SIGNUP
      const response = await apiService.apiCall('post', apiUrl, payload)
      console.log(response, '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
      if (response?.data?.code === 200) {
        addToast({
          title: TOAST.MESSAGES.SUCCESS.signUpSuccess,
          type: 'success',
        })
        navigate('/sign-in')
      } else {
        addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
      }
    } catch (error) {
      addToast({ title: TOAST.MESSAGES.ERROR.swwError, type: 'error' })
    } finally {
      hideSpinner()
    }
  }

  const password = watch('password', '')

  return (
    <div className="relative flex">
      <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
        <div className="mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
          <div>
            <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
              <div className="mt-[5vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
                  Sign Up
                </h3>
                <p className="text-gray-60 mb-3 ml-1 mt-[10px] text-base">
                  Enter your details to sign up!
                </p>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3 flex w-full items-center justify-center gap-4">
                    <div className="w-1/2">
                      <InputField
                        variant="auth"
                        extra="mb-3"
                        label="First Name"
                        placeholder="John"
                        id="firstname"
                        type="text"
                        isFieldRequired={true}
                        registrationProps={register('firstName', {
                          required: 'First Name is required',
                        })}
                        state={errors.firstName && 'error'}
                      />
                      {errors.firstName && (
                        <span className="text-red-500">
                          {errors.firstName.message}
                        </span>
                      )}
                    </div>

                    <div className="w-1/2">
                      <InputField
                        variant="auth"
                        extra="mb-3"
                        label="Last Name"
                        placeholder="Doe"
                        id="lastname"
                        type="text"
                        isFieldRequired={true}
                        registrationProps={register('lastName', {
                          required: 'Last Name is required',
                        })}
                        state={errors.lastName && 'error'}
                      />
                      {errors.lastName && (
                        <span className="text-red-500">
                          {errors.lastName.message}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Username"
                    placeholder="John123"
                    id="username"
                    type="text"
                    isFieldRequired={true}
                    registrationProps={register('userName', {
                      required: 'Username is required',
                    })}
                    state={errors.userName && 'error'}
                  />
                  {errors.userName && (
                    <span className="text-red-500">
                      {errors.userName.message}
                    </span>
                  )} */}

                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Email"
                    placeholder="mail@simmmple.com"
                    id="email"
                    type="email"
                    isFieldRequired={true}
                    registrationProps={register('email', {
                      required: 'Email is required',
                      pattern: {
                        value:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Invalid email address',
                      },
                    })}
                    state={errors.email && 'error'}
                  />
                  {errors.email && (
                    <span className="text-red-500">{errors.email.message}</span>
                  )}

                  <div className="relative">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Password"
                      placeholder="Min 8 characters"
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      isFieldRequired={true}
                      registrationProps={register('password', {
                        required: 'Password is required',
                        pattern: {
                          value:
                            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
                          message:
                            'Password must be 8+ chars with uppercase, lowercase, number, and special character',
                        },
                      })}
                      state={errors.pasaword && 'error'}
                    />
                    <div
                      className="absolute right-4 top-11 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </div>
                  </div>
                  {errors.password && (
                    <span className="text-red-500">
                      {errors.password.message}
                    </span>
                  )}

                  <div className="relative">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Confirm Password"
                      placeholder="Min 8 characters"
                      id="confirm-password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      isFieldRequired={true}
                      registrationProps={register('confirmPassword', {
                        required: 'Confirm Password is required',
                        validate: (value) =>
                          value === password || 'Passwords do not match',
                      })}
                      state={errors.confirmPassword && 'error'}
                    />
                    <div
                      className="absolute right-4 top-11 cursor-pointer"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </div>
                  </div>
                  {errors.confirmPassword && (
                    <span className="text-red-500">
                      {errors.confirmPassword.message}
                    </span>
                  )}
                  {/* 
                  <div className="mt-4 flex items-center justify-between px-2">
                    <Checkbox
                      id="checkbox"
                      {...register('termsAndConditions')}
                    />
                    <label
                      htmlFor="checkbox"
                      className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white"
                    >
                      By creating an account, you agree to the Terms and
                      Conditions, and our Privacy Policy
                    </label>
                  </div> */}

                  <button
                    type="submit"
                    className="mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    Create my account
                  </button>
                </form>

                <div className="mt-3">
                  <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                    Already a member?
                  </span>
                  <a
                    onClick={toSignIn}
                    className="ml-1 text-sm font-medium text-brand-500 hover:cursor-pointer hover:text-brand-600 dark:text-white"
                  >
                    Sign In
                  </a>
                </div>

                {/* <div className="mb-4 mt-6 flex items-center gap-3">
                  <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                  <p className="text-base font-medium text-gray-600"> or </p>
                  <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                </div> */}

                {/* <div
                  onClick={() => handleGoogleClick()}
                  className="mt-9 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-brand-500 hover:cursor-pointer dark:!bg-navy-800"
                >
                  <div className="rounded-full text-xl">
                    <FcGoogle />
                  </div>
                  <p className="text-sm font-medium text-white dark:text-navy-700">
                    Sign Up with Google
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
            <div className="absolute flex h-full w-full items-end justify-center bg-gradient-to-br from-brand-400 to-brand-600 bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]">
              <div className="flex h-full w-full">
                <img src={authImg} className="flex w-full object-scale-down" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
