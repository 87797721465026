import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import appConstants from 'common/config/appConstants'
import { IoSend } from 'react-icons/io5'

const { PLACE_HOLDER } = appConstants

/**
 * Renders an ElasticSearch component that allows users to send messages to an Assistant.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.updateStoredValues - A function to update stored values.
 * @param {string} props.sentInput - The input sent by the user.
 * @param {Function} props.handleeInputChange - A function to handle input change events.
 * @param {Object} props.responseData - The response data from the Assistant.
 * @param {boolean} props.isLoading - A flag indicating if the component is currently loading.
 * @return {JSX.Element} The rendered ElasticSearch component.
 */
const ElasticSearch = ({
  updateStoredValues,
  sentInput,
  handleeInputChange,
  responseData,
  isLoading,
}) => {
  const { handleSubmit } = useForm()
  const [inputValue, setInputValue] = useState('')

  /**
   * A function that handles input change events.
   *
   * @param {object} event - The input change event object
   * @return {void}
   */
  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  /**
   * Submits the form when triggered by a user action.
   *
   * @param {Event} event - The event that triggered the form submission.
   * @return {Promise<void>} A Promise that resolves when the form submission is complete.
   */
  const onSubmit = async (event) => {
    const newMessage = inputValue.trim()
    if (newMessage !== '') {
      updateStoredValues([newMessage])
      sentInput(inputValue)
      setInputValue('')
    }
  }

  return (
    <div extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px] '}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative flex h-14">
          <div className="mt-[6px] flex w-full items-center gap-3 rounded-2xl border border-gray-200 bg-white pl-[20px] outline-none dark:!border-white/10 dark:bg-custom-gray-bg">
            <input
              className="placeholder:text-lightFourth bg-whitetext-sm h-full w-[85.666667%] rounded-xl text-navy-700 outline-none dark:bg-custom-gray-bg dark:text-white"
              placeholder={PLACE_HOLDER.SEND_A_MESSAGE}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
            />
            <button
              type="submit"
              className={`absolute right-[0.5rem] top-3 flex h-10 w-10 items-center justify-center rounded-2xl rounded-lg ${
                isLoading || !inputValue
                  ? 'cursor-not-allowed bg-lightPrimary disabled:opacity-50 dark:bg-brand-400'
                  : 'bg-white dark:bg-brand-400'
              }`}
              disabled={!inputValue.trim() || isLoading}
            >
              <IoSend alt="Submit" className="text-brand-500 dark:text-white" />
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ElasticSearch
